<template>
	<div class="myprops">
		<div class="title">{{ $t("myprops.noumen[0]") }}</div>
		<div class="mybody">
			<div v-for="(item, index) in propsList" :key="index" @click="itemDetails(item.prop_id,index)">
				<!-- 提现分享 -->
				<div class="mybox" v-if="item.prop_category=='cash'">
					<img :src="`static/images/mypropsItem_Ti_${Language}.png`" />
				</div>
				<!-- 邀请金卡 -->
				<div class="mybox" v-else-if="item.prop_category=='invite'">
					<img :src="`static/images/mypropsItem_Yao_${Language}.png`" />
				</div>
				<!-- 签到翻倍 -->
				<div class="mybox" v-else-if="item.prop_category=='sign'">
					<img :src="`static/images/mypropsItem_Qian_${Language}.png`" />
				</div>
				<!-- 新手活动 -->
				<div class="mybox" v-else-if="item.prop_category=='novice'">
					<img :src="`static/images/mypropsItem_Qian_${Language}.png`" />
				</div>
			</div>
		</div>
		<!-- 道具详情-->
		<van-dialog v-model="show_three" :show-confirm-button="false">
			<div class="Withdrawal">
				<div class="one">
					<!-- 新手活动 -->
					<div class="one_qd" v-show="itemShow_three.prop_category=='novice'">
						<img :src="`static/images/mypropsItem_Qian_${Language}.png`" />
					</div>
					<!-- 签到翻倍 -->
					<div class="one_qd" v-show="itemShow_three.prop_category=='sign'">
						<img :src="`static/images/mypropsItem_Qian_${Language}.png`" />
					</div>
					<!-- 邀请金卡 -->
					<div class="one_yq" v-show="itemShow_three.prop_category=='invite'">
						<img :src="`static/images/mypropsItem_Yao_${Language}.png`" />
					</div>
					<!-- 提现分享 -->
					<div class="one_tx" v-show="itemShow_three.prop_category=='cash'">
						<img :src="`static/images/mypropsItem_Ti_${Language}.png`" />
					</div>
					<div class="b">
						<h3 v-show="itemShow_three.prop_category=='cash'">{{ $t("myprops.item_title[0]") }}</h3>
						<h3 v-show="itemShow_three.prop_category=='sign'">{{ $t("myprops.item_title[2]") }}</h3>
						<h3 v-show="itemShow_three.prop_category=='invite'">{{ $t("myprops.item_title[1]") }}</h3>
						<h3 v-show="itemShow_three.prop_category=='novice'">{{ $t("myprops.item_title[3]") }}</h3>
						<p>{{ $t("myprops.Popup[0]") }}：<span>{{itemShow_three.prop_join_time?itemShow_three.prop_join_time.substring(0,itemShow_three.prop_join_time.indexOf(' ')):''}}</span></p>
						<p>{{ $t("myprops.Popup[1]") }}：<span>{{(itemShow_three.prop_money*1).toFixed(1)}}R$</span></p>
					</div>
				</div>
				<div class="c">
					<p v-show="itemShow_three.prop_category=='cash'">{{ $t("myprops.item_content[0]") }}</p>
					<p v-show="itemShow_three.prop_category=='invite'">{{ $t("myprops.item_content[1]") }}</p>
					<p v-show="itemShow_three.prop_category=='sign'">{{ $t("myprops.item_content[2]") }}</p>
					<p v-show="itemShow_three.prop_category=='novice'">{{ $t("myprops.item_content[2]") }}</p>
				</div>
				<div class="d">
					<p>{{ $t("myprops.Popup[2]") }}：<span>{{itemShow_three.prop_end_term}}</span></p>
					<div class="f">
						<button class="btn" @click="getUserReceivePropReward(itemShow_three.prop_id)" v-if="itemShow_three.endTime>itemShow_three.currentTime">{{ $t("myprops.Popup[3]") }}</button>
						<button class="btn" v-else>{{ $t("myprops.Popup[4]") }}</button>
					</div>
				</div>
				<img class="img" src="/static/icon2/delete.png" alt="" @click="show_three=false" />
			</div>
		</van-dialog>
		<van-dialog v-model="update" :show-confirm-button="false" class="mess">
			<div class="end">
				<div class="end_one">
					<div @click="end()" class="end_word">
						{{ $t("myprops.Popup[6]") }}
					</div>
					<div @click="end()" class="end_word1">
						{{ $t("myprops.Popup[7]") }}
					</div>
				</div>
			</div>
			<button class="bont" @click="update=false">{{ $t("myprops.Popup[5]") }}</button>
		</van-dialog>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				box: ["one", "two", "three"],
				propsList: [],//道具list
				//   弹出框
				show_three: false,//弹出框，弹出某个道具的详情
				update: false,//领取道具成功的提示弹框
				itemShow_three: {}, //某个道具
				Language:localStorage['Language']?localStorage['Language']:'vn',//语言
			};
		},
		created() {
			this.getUserPropList()
		},
		methods: {
			//获取我的所有道具
			getUserPropList() {
				this.$Model.UserPropList(data => {
					this.propsList = data.data.data
					console.log('UserPropList',this.propsList[0])
				})
			},
			//查看item详情
			itemDetails(prop_id, index) {
				this.itemShow_three = this.propsList[index]
				// 道具过期时间，转成的number
				this.itemShow_three.endTime = Number(this.itemShow_three.prop_end_term.split(/-|:|,| /).join(''))
				// 当前时间，转成的number
				this.itemShow_three.currentTime=this.getNowFormatDate2()
				this.show_three = true;
			},
			// 领取道具
			getUserReceivePropReward(prop_id = 0) {
				this.$Model.UserReceivePropReward({
					prop_id
				}, data => {
					this.down()
					this.getUserPropList()
				})
			},
			//获取当前时间 yyyy-MM-dd HH:mm:ss,转诚number
			getNowFormatDate2() {
				var date = new Date();
				var seperator1 = "-";
				var seperator2 = ":";
				//外国的月份都是从0开始的，所以+1
				var month = date.getMonth() + 1;
				var strDate = date.getDate();
				//1-9月用0补位
				if (month >= 1 && month <= 9) {
					month = "0" + month;
				}
				//1-9日用0补位
				if (strDate >= 0 && strDate <= 9) {
					strDate = "0" + strDate;
				}
				//时
				let shi=date.getHours()
				if (shi >= 0 && shi <= 9) {
					shi = "0" + shi;
				}
				// 分
				let fen=date.getMinutes()
				if (fen >= 0 && fen <= 9) {
					fen = "0" + fen;
				}
				// 秒
				let miao=date.getSeconds()
				if (miao >= 0 && miao <= 9) {
					miao = "0" + miao;
				}
				//获取当前时间 yyyy-MM-dd HH:mm:ss
				var currentdate = date.getFullYear() + seperator1 + month + seperator1 + strDate + " " + shi +
					seperator2 + fen + seperator2 + miao;
				// 切割变数字
				currentdate=Number(currentdate.split(/-|:|,| /).join(''))
				return currentdate;
			},
			// 领取后的操作
			down() {
				this.show_three = false;
				// 提示领取成功
				this.update = true;
			},
		},
	};
</script>
<style scoped>
	.title {
		background: #4e51bf;
		width: 100%;
		height: 45px;
		color: #fff;
		font-size: 18px;
		text-align: center;
		line-height: 45px;
		position: fixed;
		top: 0px;
		z-index: 99;
	}

	.myprops {
		overflow: auto;
		height: 100%;
		width: auto;
		font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
	}

	.mybody {
		width: 100%;
		height: auto;
		padding: 20px 10px;
		margin-top: 45px;
		background: #fff;
		overflow: auto !important;
		display: flex;
		flex-wrap: wrap;
		/* justify-content: space-between; */
	}
	.mybody>div{
		margin:0 10px 10px
	}
	.head {
		width: 100%;
		font-size: 14px;
		font-weight: 600;
		text-align: center;
		line-height: 25px;
	}

	.mybox {
		width: 110px;
		height: 110px;
	}
	.mybox img{
		width: 100%;
		max-height: 100%;
		height: auto;
	}
	.onebox {
		display: flex;
		justify-content: space-between;
	}

	.bg {
		width: 70px;
		margin-top: 19px;
		margin-left: 10px;
	}

	.foot {
		position: absolute;
		top: 62px;
		left: 15px;
	}

	/* 下面是弹出框的样式 */
	.van-dialog {
		overflow: initial;
		background-image: linear-gradient(to bottom, #fe93a4, #5052be);
		padding: 8px;
		border-radius: 10px;
	}

	.head1 {
		height: 25px;
		color: #8e00ee;
		text-align: center;
		line-height: 25px;
		margin-top: 35px;
		margin-left: 1px;
		font-size: 16px;
		font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
	}

	.head2 {
		height: 25px;
		color: #8e00ee;
		text-align: center;
		line-height: 25px;
		margin-top: 40px;
		margin-left: 1px;
		font-size: 17px;
		font-weight: 600;
	}

	.head3 {
		height: 25px;
		color: #fff;
		text-align: center;
		line-height: 25px;
		margin-top: 35px;
		margin-left: 1px;
		font-size: 17px;
		font-weight: 600;
	}

	.foot2 {
		position: absolute;
		top: 65px;
		left: 20px;
	}

	/* 弹窗 */
	.Withdrawal {
		background: #fff;
		position: relative;
	}

	.one {
		display: flex;
		flex-direction: row;
	}

	.a {
		min-width: 90px;
		height: 90px;
		margin: 18px;
		border-radius: 10px;
		color: #fe889d;
		position: relative;
		overflow: hidden;
		background-repeat: no-repeat;
	}
	.one_yq,.one_qd,.one_tx {
		height: 105px;
		width: 105px;
		margin: 18px 0 0px 18px;
		flex-shrink: 0;
	}
	.one_yq img,.one_qd img,.one_tx img{
		width: 100%;
		height: auto;
		max-height: 100%;
	}
	.b h3 {
		font-size: 18px;
		font-weight: 600;
		margin-top: 10px;
	}
	.b h3:nth-child(1){
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.b {
		flex: 1;
		margin-left: 10px;
		height: 100%;
		line-height: 30px;
		margin-top: 18px;
		font-size: 13px;
		overflow: hidden;
	}

	.b p {
		font-weight: 600;
	}

	.b span {
		color: #4e51bf;
	}

	.c {
		background: #eff0f2;
		padding: 20px 30px 20px 30px;
		margin: 0 20px 10px 20px;
		line-height: 23px;
		font-size: 14px;
	}

	.d {
		text-align: center;
		font-size: 18px;
	}

	.d p {
		margin: 10px 0 20px 0;
		font-size: 13px;
	}

	.f {}

	.f button {
		background: #4e51bf;
		color: #fff;
		width: 140px;
		height: 40px;
		border: none;
		border-radius: 18px;
		font-size: 17px;
	}

	.img {
		width: 30px;
		height: 30px;
		position: absolute;
		top: 107%;
		left: 50%;
		transform: translate(-50%, 0);
	}

	.end_word {
		text-align: center;
		height: 40px;
		line-height: 40px;
		color: #fd4133;
		font-size: 18px;
	}

	.end_word1 {
		text-align: center;
		height: 40px;
		color: #a500ff;
		font-size: 16px;
	}

	.end {
		width: 200px;
		background-image: linear-gradient(to bottom, #fe93a4, #5052be);
		margin: auto;
		margin-left: 59px;
		padding: 4px;
		border-radius: 10px;
		margin-top: 90px;
	}

	.end_one {
		background: #fff;
		border-radius: 8px;
	}

	.bont {
		background: #ffde7e;
		color: #f2752f;
		width: 150px;
		height: 30px;
		border-radius: 10px;
		border: none;
		margin-left: 80px;
		margin-top: 60px;
	}

	.btn,
	.btn2 {
		margin-bottom: 20px;
	}

	.mess {
		height: 300px;
		background: url("../../../public/static/images/tc.png") no-repeat;
		background-size: 100%;
	}
</style>
